import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";

import "./components/i18n";

import Menu from "./components/Menu";
import CookieConsent from "./components/CookieConsent";
import VideoSection from "./components/VideoSection"
import Product from "./components/Product"
import Customer from "./components/Customer"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CookiesProvider>
        <Menu />
        <CookieConsent />
        <VideoSection />
        <Product />
        <Customer />
        <ContactUs />
        <Footer />
    </CookiesProvider>
  </React.StrictMode>
);