import { useTranslation } from "react-i18next";

function Footer() {

    const { t } = useTranslation();
    const application: any[string] = t("application");

    return(
        <footer className="page-footer font-small blue pt-4" style={{ marginTop: 20, color: "white", backgroundColor: "#6f42c1" }}>
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-6 mt-md-0 mt-3">
                    </div>
                    <div className="col-md-3 mb-md-0 mb-3">
                    </div>
                    <div className="col-md-3 mb-md-0 mb-3">
                        <ul className="list-unstyled">
                            <li><a href="#">{application.backToTop}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">
                &copy; {application.copyRights}
            </div>
        </footer>
    );
}

export default Footer;